import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class HistoryService extends BaseApiService<Models.HistoryItem> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.HistoryItem);
    }

    public getByNet(netId: number): Promise<Array<Models.HistoryItem>> {
        return this.get('get-by-net/' + netId) as any;
    }
}
