import { autoinject } from 'aurelia-framework';
import { ExtraEquipmentType } from 'models/ExtraEquipment';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ExtraEquipmentTypeService extends BaseApiService<ExtraEquipmentType> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ExtraEquipmentType);
  }
}
