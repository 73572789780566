import { autoinject } from 'aurelia-framework';
import { NetlogHttpClient } from 'lib/http';
import { MaterialImpregnationUptakeFactor } from 'models';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ImpregnationTypeService extends BaseApiService<Models.ImpregnationType> {
  constructor(
    httpClient: AuthHttpClient,
    private client: NetlogHttpClient
  ) {
    super(httpClient, Models.ImpregnationType);
  }

  public getAllCached(): Promise<Array<Models.ImpregnationType>> {
    // if the cache is already filled, return that - if not, fill the cache and return it
    if (this.cachedObjects) {
      return Promise.resolve(this.cachedObjects);
    }

    return this.getAll('/getallwithdeleted').then((res) => {
      res = res.sort(function (a, b) {
        return a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0;
      });
      this.cachedObjects = res;
      return res;
    });
  }

  public async removeMaterialUptakeFactor(id: number, materialUptakeId: number) {
    return await this.client.delete({
      url: `api/impregnationtypes/${id}/materialimpregnationuptakefactors/${materialUptakeId}`,
    });
  }

  public async getMaterialUptakeFactorsForNet({
    impregnationTypeId,
    netId,
  }: {
    impregnationTypeId: number;
    netId: number;
  }) {
    return await this.client.get<MaterialImpregnationUptakeFactor[]>({
      url: `api/impregnationtypes/${impregnationTypeId}/impregnation-material-uptake-factor?netId=${netId}`,
      model: MaterialImpregnationUptakeFactor,
    });
  }
}
