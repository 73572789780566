import { autoinject } from 'aurelia-framework';
import { saveAs } from 'file-saver';
import { AuthHttpClient } from 'services/auth-http-client';

@autoinject
export class FileService {
  private baseUrl: string = '/api/customers/{customerId}/files';

  constructor(private httpClient: AuthHttpClient) {}

  public upload(file: any, customerId: number): Promise<any> {
    const formData = new FormData();
    formData.append('files', file, file.name ? file.name : 'files');

    const apiUrl = this.baseUrl.replace('{customerId}', customerId.toString());

    return this.httpClient
      .fetch(apiUrl, {
        method: 'POST',
        body: formData,
        headers: {},
      })
      .then((res) => {
        if (res.status < 400) {
          return res.text().then((responseText) => {
            const attachmentData = JSON.parse(responseText);
            return attachmentData;
          });
        } else {
          return false;
        }
      });
  }

  public getMetaData(id: any, customerId: number): Promise<any> {
    const apiUrl = this.baseUrl.replace('{customerId}', customerId.toString());

    return this.httpClient
      .fetch(apiUrl + '/' + id + '/metadata', {
        method: 'GET',
        headers: {},
      })
      .then((response) => {
        if (response.status < 400) {
          return response.text().then((responseText) => {
            return JSON.parse(responseText);
          });
        } else {
          return response.text().then((responseText) => {
            throw new Error('An unexpected server error occurred: ' + responseText);
          });
        }
      });
  }

  public async preview(id: string | number, customerId?: number, save = false) {
    const apiUrl = this.baseUrl.replace('{customerId}', customerId.toString()) + '/' + id;
    const response = await this.httpClient.execNoTransform(apiUrl, {
      method: 'GET',
      headers: {},
    });

    let filename = '';
    const disposition = response.headers.get('content-disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    if (save) {
      return response.blob().then((blob) => {
        saveAs(blob, filename);
      });
    } else {
      return await response.blob();
    }
  }

  public async download(
    id: string | number,
    customerId?: number,
    apiUrl?: string,
    save: boolean = true
  ): Promise<string | unknown> {
    if (!apiUrl) {
      apiUrl = this.baseUrl.replace('{customerId}', customerId.toString()) + '/' + id;
    }

    const response = await this.httpClient.fetch(apiUrl, { method: 'GET', headers: {} });

    if (response.status < 400) {
      let filename = '';
      const disposition = response.headers.get('content-disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      if (save) {
        return saveAs(await response.blob(), filename);
      }

      return await response.blob();
    } else {
      return response.text().then((responseText) => {
        if (response.status == 404) {
          throw new Error(`File not found`);
        } else {
          throw new Error('An unexpected server error occurred: ' + responseText);
        }
      });
    }
  }
}
