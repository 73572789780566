import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ImageService {
    private baseUrl: string = '/api/images';

    constructor(private httpClient: AuthHttpClient) {

    }

    public Upload(file: any): Promise<string> {
        const formData = new FormData();
        formData.append('files', file, file.name ? file.name : 'files');

        return this.httpClient.fetch(this.baseUrl, {
            method: 'POST',
            body: formData,
            headers: {}
        }).then((res) => {
            if (res.status < 400) {
                return res.text().then((responseText) => {
                    const imageData = JSON.parse(responseText);
                    return imageData.Id;
                });
            } else {
                return false;
            }
        });
    }
}
