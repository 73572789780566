import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ImpregnationService extends BaseApiService<Models.Impregnation> {
    constructor(httpClient: AuthHttpClient) {
        super(httpClient, Models.Impregnation);
    }

    public calculateImpregnationAmount(netId: number): Promise<any> {
        return this.get('calculate-impregnation-amount/' + netId);
    }
}
