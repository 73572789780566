import { FileAttachmentModels } from './../models/FileAttachmentModels';
import { autoinject, transient } from 'aurelia-framework';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';
import { Visibility } from 'models/FileAttachment';

@autoinject
@transient()
export class FileAttachmentService extends BaseApiService<FileAttachmentModels.FileAttachment> {
  private context: string;

  constructor(httpClient: AuthHttpClient) {
    super(httpClient, FileAttachmentModels.FileAttachment);
  }

  public setApiUrl(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  public setContext(context: string) {
    this.context = context;
  }

  public getFiles(parentId: number, visibility?: Visibility): Promise<any> {
    if (this.context) {
      return this.get(parentId + '/files?context=' + this.context + `${visibility ? '&visibility=' + visibility : ''}`);
    }
    return this.get(parentId + '/files' + `${visibility ? '?visibility=' + visibility : ''}`);
  }

  public addFile(parentId: number, fileId: number, visibility?: Visibility): Promise<any> {
    const visibilityQuery = visibility ? '?visibility=' + visibility : '';
    if (this.context) {
      return this.post({ context: this.context } as any, '/' + parentId + '/files/' + fileId + visibilityQuery);
    }
    return this.post(null, '/' + parentId + '/files/' + fileId + visibilityQuery);
  }

  public deleteFile(parentId: number, fileId: number): Promise<any> {
    return this.delete(parentId + '/files/' + fileId);
  }
}
