import { autoinject } from 'aurelia-framework';
import { ExtraEquipment } from 'models/ExtraEquipment';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class ExtraEquipmentService extends BaseApiService<ExtraEquipment> {
  constructor(httpClient: AuthHttpClient) {
    super(httpClient, ExtraEquipment);
  }
}
