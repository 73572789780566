import { I18N } from 'aurelia-i18n';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { AuthHttpClient } from 'services/auth-http-client';
import { BaseApiService } from 'services/base-api-service';

@autoinject
export class GeneralOfferService extends BaseApiService<Models.GeneralOffer> {
    constructor(httpClient: AuthHttpClient, private i18n: I18N) {
        super(httpClient, Models.GeneralOffer);
    }

    public exportList(segmentId, internalDepartmentId, statusId): Promise<any> {
        let queryString = '?locale=' + this.getLocale();

        if (segmentId) {
            queryString += '&segmentId=' + segmentId;
        }

        if (internalDepartmentId) {
            queryString += '&internalDepartmentId=' + internalDepartmentId;
        }

        if (statusId) {
            queryString += '&statusId=' + statusId;
        }

        return this.getBlob('export' + queryString);
    }

    private getLocale(): string {
        let locale = this.i18n.getLocale();

        if (locale.indexOf('NO') !== -1) {
            locale = 'NO';
        }

        return locale;
    } 
}
