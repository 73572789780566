import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ToastService } from './toast-service';

const logger: Logger = LogManager.getLogger('error-service');

@autoinject
export class ErrorService {
  public constructor(private toastService: ToastService) {}

  public handleError(err: any) {
    logger.error('An error occurred', err);
    this.toastService.showError(null, 'general.error', err);
  }
}
